<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Offer ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span v-if="readonly">
          <i class="fad fa-envelope mr-2"></i> View: {{ selected.name }}
        </span>
        <span v-else> <i class="fad fa-envelope mr-2"></i> Update: {{ selected.name }} </span>
        <!-- <entity-id
          class="ml-2"
          :label="selected.id"
          :path="offersUrl"
          title="Offer"
        ></entity-id> -->
        <resource-offer-state class="ml-2" :state="selected.state"></resource-offer-state>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create an Offer
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1">
          <i class="fad fa-clock mr-2"></i>Offered Hours
          <v-chip
            :color="slideouts.update.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ totalOfferedHours }}
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Offer ...</p>
    </v-container>

    <v-tabs-items v-model="slideouts.update.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-container fluid class="pa-4">
          <v-form v-model="forms.tab0" ref="updateForm0">
            <v-row dense>
              <v-col cols="12" md="6" class="pb-0">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                  label="Name"
                  placeholder="Offer Name"
                  v-model="selected.name"
                  readonly
                ></v-text-field-alt>
              </v-col>
              <v-col cols="12" md="12" class="mb-4">
                <rich-text-editor
                  ref="richTextEditor"
                  v-model="selected.justification"
                  title="Offer Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
                  showLabel
                  allowExpand
                  :readonly="readonly"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%">
          <v-form
            v-model="forms.tab1"
            ref="updateForm1"
            style="height: 100%; display: flex; flex-direction: column"
          >
            <v-row class="mt-0">
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Take hours from
                  <span class="opacity-64 fs-12px">"Source"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  :readonly="!managementMode || readonly"
                  :isUsersLoading="isUsersLoading"
                  v-model="selected.sourceUserId"
                  @change="sourceUserChanged"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Offer hours to
                  <span class="opacity-64 fs-12px">"Target"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  :readonly="readonly"
                  :isUsersLoading="isUsersLoading"
                  v-model="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
            </v-row>
            <!-- <v-divider class="mt-4"></v-divider> -->
            <div class="mt-6 mb-0 d-flex flex-row align-center" style="gap: 1rem">
              <label class="input-label">
                <i class="fad fa-clock mr-2"></i>Offered Hours
                <v-chip
                  color="secondary"
                  dark
                  class="ml-2 font-weight-bold"
                  small
                  label
                  style="height: 20px"
                  >{{ totalOfferedHours }}
                </v-chip>
              </label>
              <v-btn
                color="deep-purple py-0"
                height="28px"
                max-height="28px"
                :dark="!readonly && selected.sourceUserId != null"
                :disabled="readonly || selected.sourceUserId == null"
                @click="openAddOfferHours"
              >
                <i class="fad fa-plus-minus mr-2"></i><span class="fs-12px">Update Selection</span>
              </v-btn>
              <h4
                v-if="sourceMatchesTarget"
                class="d-flex align-center red lighten-5 red--text rounded-lg px-3"
                style="line-height: 28px"
              >
                <i class="fad fa-circle-exclamation mr-2"></i> Source & Target Users Must Not Match!
              </h4>
            </div>
            <div
              class="pa-5 inset-shadow mt-2"
              style="
                background: #eceff1;
                border-radius: 0.5rem;
                overflow: auto;
                overflow: overlay;
                height: 100%;
              "
            >
              <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
                <div
                  v-if="selected.offerRecords != null && selected.offerRecords.length == 0"
                  key="empty"
                  style="gap: 1rem"
                  class="d-flex align-center justify-center flex-column"
                >
                  <i class="fad fa-horse-saddle fa-swap-opacity" style="font-size: 7rem"></i>
                  <h3 class="mb-1">Nothing to offer yet!</h3>
                  <v-btn
                    color="deep-purple"
                    height="28px"
                    max-height="28px"
                    :dark="!readonly && selected.sourceUserId != null"
                    :disabled="readonly || selected.sourceUserId == null"
                    @click="openAddOfferHours"
                  >
                    <i class="fad fa-plus-minus mr-2"></i
                    ><span class="fs-12px">Update Selection</span>
                  </v-btn>
                  <h4 v-if="selected.sourceUserId == null" class="mb-0 orange--text text--darken-2">
                    <i class="fas fa-circle-exclamation mr-2"></i>Select Source User First!
                  </h4>
                </div>
                <v-slide-y-transition
                  v-else
                  key="not-empty"
                  mode="out-in"
                  group
                  style="width: 100%; gap: 1rem"
                  class="d-flex flex-column"
                >
                  <offer-record-card
                    v-for="(offerRecord, i) in selected.offerRecords"
                    :key="'or_' + i"
                    :value="selected.offerRecords[i]"
                    :readonly="readonly"
                    @delete="deleteOfferRecord(offerRecord)"
                    fullWidth
                    allowDelete
                  ></offer-record-card>
                </v-slide-y-transition>
              </v-slide-y-reverse-transition>
            </div>
          </v-form>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <add-offer-hours
      ref="addOfferHours"
      v-model="selected"
      :users="users"
      :isUsersLoading="isUsersLoading"
      @close="isAddOfferHoursPanelOpen = false"
      :management-mode="managementMode"
      nested
    ></add-offer-hours>

    <template v-slot:footer>
      <v-card-actions>
        <div class="d-flex ml-2" v-if="!isFetching && canSendToTarget">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="green"
                :loading="slideouts.update.isSending"
                :disabled="slideouts.update.isLoading || slideouts.update.hasChanges"
                @click="sendToTarget"
              >
                <i class="fad fa-swap-opacity fa-paper-plane mr-2"></i>
                Send To Target
              </v-btn>
            </template>
            <span class="d-flex align-center"> Send this offer to the selected target user! </span>
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            isMainRoute &&
            selected.state == enums.ResourceOfferState.Draft.value &&
            $has(perms.ResourceOffers.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  sourceMatchesTarget ||
                  !validOfferRecords ||
                  !validJustification ||
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  sourceMatchesTarget ||
                  !validOfferRecords ||
                  !validJustification ||
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchOffer()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Offer</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="del"
              v-if="
                $has(perms.ResourceOffers.Delete) &&
                selected.state == enums.ResourceOfferState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="
                (!$has(perms.ResourceOffers.Update) && !$has(perms.ResourceOffers.Delete)) ||
                selected.state != enums.ResourceOfferState.Draft.value
              "
              >No Actions Available!</v-subheader
            >
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import offersAPI from "../services/resource-offers-service";
import OfferRecordCard from "./OfferRecordCard.vue";
import ResourceOfferState from "./ResourceOfferState.vue";
import AddOfferHours from "./AddOfferHours.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";

export default {
  name: "edit-offer",
  components: {
    PanelSizeControl,
    UserAllSelector,
    AddOfferHours,
    OfferRecordCard,
    ResourceOfferState,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      offerId: null,
      closeAfter: false,
      isFetching: false,
      readonly: false,
      isUsersLoading: false,
      isAddOfferHoursPanelOpen: false,
      users: [],
      selected: {
        offerRecords: [],
      },
      selectedCemented: {},
      forms: {
        tab0: true,
        tab1: true,
      },
      slideouts: {
        update: {
          tab: 0,
          active: false,
          fullWidth: false,
          isLoading: false,
          isSending: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      if (this.forms.tab0 && this.forms.tab1) return true;
      return false;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
    offersUrl() {
      if (this.selected == null) return "";
      return `offers/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-offer") return true;
      else return false;
    },
    totalOfferedHours() {
      if (!this.slideouts.update.active || this.isFetching) return 0;
      else
        return this.selected.offerRecords.reduce((total, cur) => {
          return total + cur.offeredHours;
        }, 0);
    },
    canSendToTarget() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Update) &&
        this.selected.state == enums.ResourceOfferState.Draft.value &&
        this.selected.sourceUserId == this.loggedInUser.id
      );
    },
    sourceMatchesTarget() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return (
        this.selected.sourceUserId == this.selected.targetUserId &&
        this.selected.sourceUserId != null
      );
    },
    validOfferRecords() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return this.selected.offerRecords.length > 0;
    },
    validJustification() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return (
        this.selected.justification != null &&
        this.selected.justification.trim() != "<p></p>" &&
        this.selected.justification.trim() != ""
      );
    },
  },
  created() {
    this.getAllUsersData();
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.sourceMatchesTarget &&
        this.validOfferRecords &&
        this.validJustification &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.sourceMatchesTarget &&
        this.validOfferRecords &&
        this.validJustification &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.selected.state == enums.ResourceOfferState.Draft.value &&
        this.$has(perms.ResourceOffers.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode();
      }
    },
    sendToTarget() {
      this.$dialog
        .warning({
          text: `Are you sure you want to send this Offer to the target user?<br/>
            <h4 class="fs-12px">${this.selected.name}</h4>
          `,
          title: `Send Offer to Target?`,
          color: "green",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "green",
              handle: () => {
                this.slideouts.update.isSending = true;
                return offersAPI
                  .sendToTarget(this.selected.id)
                  .then((resp) => {
                    this.slideouts.update.isSending = false;
                    this.onSendToTargetSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Offer sent to target user successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.slideouts.update.isSending = false;
                    this.$dialog.notify.error("Error sending offer!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onSendToTargetSuccess(id) {
      this.$emit("sent", id);
    },
    sourceUserChanged() {
      this.selected.offerRecords = [];
    },
    openAddOfferHours() {
      this.isAddOfferHoursPanelOpen = true;
      this.$refs.addOfferHours.open();
    },
    deleteOfferRecord(offerRecord) {
      //remove
      var offerIdx = this.selected.offerRecords.findIndex((or) => or.id == offerRecord.id);
      this.selected.offerRecords.splice(offerIdx, 1);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      if (this.isAddOfferHoursPanelOpen) {
        // Avoid to close the inner Slide while outer mask clicked
        e.wait = true;
        return;
      }
      this.isAddOfferHoursPanelOpen = false;

      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementOffer();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewOffer) {
      this.$emit("save", this.selected, isNewOffer);
    },
    onResponse(resp, toSend) {
      this.slideouts.update.isLoading = false;
      var message = "Offer updated successfully!";
      this.$log(">>> updated", resp.data);
      this.selected = resp.data;
      this.cementOffer();
      var isNewOffer = false;
      if (!toSend.id) {
        isNewOffer = true;
        this.offerId = this.selected.id;
        message = "Offer added successfully!";
      }
      this.announceChange(isNewOffer);
      this.$dialog.notify.success(message, {
        position: "top-right",
        timeout: 3000,
      });
      this.readonly = true;
      this.checkForChanges();
      if (this.closeAfter) {
        this.closeUpdateSlideout();
      }
    },
    onCatch(err) {
      this.slideouts.update.isLoading = false;
      this.$handleError(err);
    },
    updateConfirmed(closeAfter) {
      this.closeAfter = closeAfter;
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      if (this.managementMode) {
        offersAPI
          .updateByManagement(toSend)
          .then((resp) => this.onResponse(resp, toSend))
          .catch(this.onCatch);
      } else {
        offersAPI
          .update(toSend)
          .then((resp) => this.onResponse(resp, toSend))
          .catch(this.onCatch);
      }
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      this.$log(">>>>>>> open", id, editMode);
      if (id == null) {
        this.selected = {
          name: null,
          sourceUserId: null,
          targetUserId: null,
          offerRecords: [], // {constructionPhaseRecordId, offeredHours}
        };
        if (this.managementMode) this.selected.sourceUserId = null;
        else this.selected.sourceUserId = this.loggedInUser.id;

        this.readonly = false;
        this.isFetching = false;
        this.offerId = null;
        this.refreshName();
        this.cementOffer();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
      } else {
        this.readonly = !editMode;
        this.offerId = id;
        this.fetchOffer();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },
    fetchOffer() {
      this.isFetching = true;
      offersAPI
        .getById(this.offerId)
        .then((resp) => {
          this.$log("getOfferById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementOffer();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getOfferById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementOffer() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementOffer()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      if (this.validJustification) {
        this.slideouts.update.tab = 0;
        setTimeout(() => {
          this.$refs.richTextEditor.focus();
        }, 100);
        return;
      }

      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm1, () => {
          this.slideouts.update.tab = 1;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Offer?<br/><h4 class="fs-12px">${this.selected.name}</h4>`,
          title: `Delete Offer?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return offersAPI
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteOfferSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Offer deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Offer!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteOfferSuccess(id) {
      this.$emit("delete", id);
    },
    refreshName() {
      var sourceName = "{{Source}}";
      var targetName = "{{Target}}";
      if (this.selected.sourceUserId != null) {
        var sourceUser = this.users.find((u) => u.id == this.selected.sourceUserId);
        sourceName = `${sourceUser.firstName} ${sourceUser.lastName}`;
      }
      if (this.selected.targetUserId != null) {
        var targetUser = this.users.find((u) => u.id == this.selected.targetUserId);
        targetName = `${targetUser.firstName} ${targetUser.lastName}`;
      }
      this.selected.name = `Offer hours from ${sourceName} to ${targetName}`;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.sourceUserId": {
      handler() {
        this.refreshName();
      },
      deep: true,
    },
    "selected.targetUserId": {
      handler() {
        this.refreshName();
      },
      deep: true,
    },
  },
};
</script>
