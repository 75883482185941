import Api from "../../Shared/services/api";

const baseUrl = "ResourceOffers";
export default {
  getById(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  delete(id) {
    return Api().delete(`${baseUrl}/${id}`);
  },
  update(entity) {
    if (!entity.id) return Api().post(`${baseUrl}`, entity);
    else return Api().put(`${baseUrl}/${entity.id}`, entity);
  },
  updateByManagement(entity) {
    if (!entity.id) return Api().post(`${baseUrl}/Management`, entity);
    else return Api().put(`${baseUrl}/Management/${entity.id}`, entity);
  },
  getSentOffers(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/Sent?` + qParams.toString());
  },
  getRecievedOffers(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/Recieved?` + qParams.toString());
  },
  getOffersForManagement(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/Management?` + qParams.toString());
  },
  sendToTarget(id) {
    return Api().patch(`${baseUrl}/${id}/SendToTarget`);
  },
  acceptOffer(id) {
    return Api().patch(`${baseUrl}/${id}/Accept`);
  },
  declineOffer(id) {
    return Api().patch(`${baseUrl}/${id}/Decline`);
  },
  approveOffer(id) {
    return Api().patch(`${baseUrl}/Management/${id}/Approve`);
  },
  rejectOffer(id) {
    return Api().patch(`${baseUrl}/Management/${id}/Reject`);
  },
  getActivityLog(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
};
