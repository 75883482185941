<template>
  <v-card
    v-if="value != null"
    elevation="2"
    :class="{
      'contract-phase-record-card-no-border': noBorder,
      'contract-phase-record-card-valid-choice': validChoice,
    }"
    class="contract-phase-record-card d-flex align-center justify-space-between flex-row py-2 px-3"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${value.project.id}`"
          >{{ value.project.fullName }}
        </a>
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{ value.startDate.substr(0, 7) }}</span>
        </v-chip>
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column align-end text-right" style="gap: 3px">
        <b class="blue-grey--text" style="white-space: nowrap">Source Labor</b>
        <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
          getLaborTypeObj(value.sourceLaborType).desc
        }}</b>
      </div>
      <v-divider vertical class="mx-1"></v-divider>
      <div class="d-flex flex-column align-end text-right" style="gap: 3px">
        <b class="blue-grey--text" style="white-space: nowrap">Target Labor</b>
        <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
          getLaborTypeObj(value.targetLaborType).desc
        }}</b>
      </div>
      <v-divider vertical class="mx-1"></v-divider>
      <div class="d-flex flex-column align-end pr-1" style="gap: 3px">
        <b class="blue-grey--text" style="white-space: nowrap">Offered</b>
        <span class="d-flex align-center" style="gap: 0.35rem">
          <span class="fs-12px font-weight-medium opacity-64">Hours:</span>
          <span
            style="line-height: 0"
            class="info--text fs-18px font-weight-medium font-weight-medium mono-font"
            >{{ value.offeredHours }}
          </span>
        </span>
      </div>
      <v-divider v-if="allowDelete" vertical></v-divider>
      <v-btn
        v-if="allowDelete"
        icon
        small
        color="error"
        @click="deleteOfferRecord"
        :disabled="readonly"
      >
        <i class="far fa-trash-alt fs-14px"></i>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "offer-record-card",
  components: {},
  data() {
    return {
      enums,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validChoice() {
      if (this.value.offeredHours != 0) return true;
      return false;
    },
  },
  mounted() {},
  methods: {
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
    deleteOfferRecord() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss"></style>
